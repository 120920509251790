exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-courses-tsx": () => import("./../../../src/pages/admin/courses.tsx" /* webpackChunkName: "component---src-pages-admin-courses-tsx" */),
  "component---src-pages-admin-grades-tsx": () => import("./../../../src/pages/admin/grades.tsx" /* webpackChunkName: "component---src-pages-admin-grades-tsx" */),
  "component---src-pages-admin-students-tsx": () => import("./../../../src/pages/admin/students.tsx" /* webpackChunkName: "component---src-pages-admin-students-tsx" */),
  "component---src-pages-admin-teachers-tsx": () => import("./../../../src/pages/admin/teachers.tsx" /* webpackChunkName: "component---src-pages-admin-teachers-tsx" */),
  "component---src-pages-auth-google-tsx": () => import("./../../../src/pages/auth/google.tsx" /* webpackChunkName: "component---src-pages-auth-google-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-student-index-tsx": () => import("./../../../src/pages/student/index.tsx" /* webpackChunkName: "component---src-pages-student-index-tsx" */),
  "component---src-pages-student-payment-tsx": () => import("./../../../src/pages/student/payment.tsx" /* webpackChunkName: "component---src-pages-student-payment-tsx" */),
  "component---src-pages-teacher-index-tsx": () => import("./../../../src/pages/teacher/index.tsx" /* webpackChunkName: "component---src-pages-teacher-index-tsx" */)
}

